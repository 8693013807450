<script lang="ts">
  import type { UserQuestionWithAnswer } from '../domain/question'
  import { isCreatorLogin_ } from '../services/store'
  import QuestionEditSheet from './QuestionEditSheet.svelte'

  export let qaList: UserQuestionWithAnswer[] = []

  let editingQAIndex: number | null = null
</script>

<div
  class="wrapper  mt-2 mb-8 overflow-x-hidden md:max-w-[38rem] md:mx-auto overflow-visible "
  style="overflow: visible;"
  on:click={e => {
    const idx = e.target.getAttribute('data-idx')
    // console.log('idx', idx, e)
    if (typeof idx !== 'undefined') {
      editingQAIndex = parseInt(idx, 10)
    }
  }}
>
  <!-- mask -->
  <!-- <div class="top-mask sticky top-0 w-full h-8 " /> -->

  {#each qaList as qaItem, idx (qaItem.id)}
    <div class={qaItem.answer ? '' : 'inline-block'}>
      <div
        class="bg-gray-200 rounded-2xl rounded-bl-none translate-x-4 w-fit max-w-[85%] mt-6 mb-3 inline-block"
      >
        <div class="absolute bottom-0 bg-gray-200 w-12 h-3 -left-6" />
        <div
          class="absolute bottom-0 bg-white z-10 w-6 h-7 -left-6 rounded-br-[14px] translate-y-[1px]"
        />
        <div class="px-3 py-1.5 w-full ">{qaItem.question}</div>
      </div>
      {#if $isCreatorLogin_}
        <button
          class="px-3 py-1.5 border-none inline-block ml-5 mr-2 rounded-full  {qaItem.answer
            ? 'bg-secondary text-gray-700'
            : 'bg-[#5b6dec] text-white'}"
          data-idx={idx}
        >
          {qaItem.answer ? '更新回答' : '回答/处理'}
        </button>
      {/if}
    </div>

    {#if !!qaItem.answer}
      <div
        class="bg-primary text-white rounded-2xl rounded-br-none -translate-x-4 w-fit ml-auto max-w-[85%] mt-3"
      >
        <div class="absolute  bottom-0 bg-primary w-12 h-3 -right-6" />
        <div
          class="absolute  bottom-0 bg-white z-10 w-6 h-7 -right-6 rounded-bl-[14px] translate-y-[1px]"
        />
        <div class="px-3 py-1.5 w-full ">{qaItem.answer}</div>
      </div>
    {/if}

    <!-- mask -->
    <!-- <div class="sticky bottom-0 bottom-mask w-full h-8 " /> -->
  {/each}
</div>

<QuestionEditSheet
  qaPair={qaList[editingQAIndex]}
  dismiss={() => (editingQAIndex = null)}
/>

<!-- <style>
  .top-mask {
    background: linear-gradient(to bottom, #fff, transparent);
    z-index: 100;
  }
  .bottom-mask {
    background: linear-gradient(to bottom, transparent, #fff);
    z-index: 100;
  }
</style> -->
