import ddfDataAdapter from '../services/ddfDataAdapter';
import notificationAdapter from '../services/notificationAdapter';
import { qaPairs_ } from '../services/store';
export function askQuestion(question) {
    ddfDataAdapter
        .askQuestion(question)
        .then(res => {
        notificationAdapter.toast('已发送提问');
        qaPairs_.update(qaPairs => [
            {
                question: question.question,
                answer: '',
                id: 'id_new_question' + Math.random(),
                ip: 'ip_placeholder',
                hide: '0',
                time: String(Date.now),
                to_user: question.creatorUserKey,
            },
            ...qaPairs,
        ]);
    })
        .catch(err => {
        notificationAdapter.toast('发送失败：' + JSON.stringify(err));
    });
}
