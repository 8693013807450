<script lang="ts">
  import { onMount } from 'svelte'

  import { Router, Route, navigate } from 'svelte-routing'
  import Toast from './components/Toast.svelte'
  import CreatorPage from './CreatorPage.svelte'
  import Endpoints from './lib/Endpoints'

  export let url = ''

  onMount(() => {
    DEV_ENV && navigate('/@fun')
  })
</script>

<Router {url}>
  <Route path="/card/:key" let:params>
    <img class="w-96 h-96" src="{Endpoints.SHARE_CARD}/{params.key}" alt="" />
  </Route>
  <Route path="/*">
    <CreatorPage />
  </Route>
</Router>
<Toast />

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  :root {
    --primary-color: #6f6c74;
    --secondary-color: #cacaca;
  }
  body {
    @apply font-sans;
  }

  @layer utilities {
    .color-primary {
      color: var(--primary-color);
      transition: color 0.5s ease-in-out;
    }
    .color-secondary {
      color: var(--secondary-color);
      transition: color 0.5s ease-in-out;
    }

    .bg-primary {
      background: var(--primary-color);
      transition: background 0.5s ease-in-out;
    }
    .bg-secondary {
      background: var(--secondary-color);
      transition: background 0.5s ease-in-out;
    }

    .md-limit {
      @apply md:max-w-xl md:mx-auto;
    }
  }

  @layer base {
    .mx-page {
      @apply mx-8;
    }
  }

  @layer components {
    .heading {
      @apply text-4xl color-secondary  mt-6 bg-white;
    }
  }
</style>
