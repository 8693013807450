export default {
    isChildOf(node, parentNode) {
        while (node !== null) {
            if (node === parentNode) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    },
};
export function DQ(selector) {
    return document.querySelector(selector);
}
