export const FoodList = [
    '🍞',
    '🍌',
    '🍗',
    '🍩',
    '🥤',
    '🥗',
    '🌭',
    '🍔',
    '🍜',
    '🍛',
    '🍕',
    '🥘',
    '🍦',
];
export const FoodPriceList = [
    100, 100, 800, 1000, 1500, 2000, 2000, 3000, 3500, 4000, 5000, 10000, 600,
];
export const FoodMealCountList = [
    0.1, 0.1, 0.2, 0.2, 0.2, 0.3, 0.3, 0.3, 0.5, 0.7, 0.8, 1, 2, 0.2,
];
export function getFoodById(id) {
    if (id < 0 || id > FoodList.length) {
        id = 1;
    }
    const idx = id - 1;
    return {
        id,
        name: FoodList[idx],
        price: FoodPriceList[idx],
        mealCount: FoodMealCountList[idx],
    };
}
export function getFoodByName(name) {
    const found = FoodList.indexOf(name);
    let idx = found;
    if (found < 0) {
        idx = 0;
    }
    return getFoodById(idx + 1);
}
export function getFoodListForDisplay() {
    const list = FoodList.map((_, idx) => ({
        id: idx + 1,
        name: FoodList[idx],
        price: FoodPriceList[idx],
        mealCount: FoodMealCountList[idx],
    }));
    const iceCream = list.pop();
    list.splice(2, 0, iceCream);
    return list;
}
