<script lang="ts">
  import type { SocialLinkItem, SocialLinkObject } from '../domain/creator'
  import Icons from '../lib/icons.svelte'
  import url from '../lib/url'
  import notificationAdapter from '../services/notificationAdapter'
  import { copiable } from '../lib/actions/Copiable'

  export let socialLinkObject: SocialLinkObject | null = null

  const ICON_W = 36

  $: linkList = Object.keys(socialLinkObject ?? {})
    .map(key => {
      const isUrl = url.validateURL(socialLinkObject[key])

      return {
        linkName: key,
        type: isUrl ? 'url' : 'text',
        content: socialLinkObject[key],
      } as SocialLinkItem
    })
    .filter(item => item.content)
</script>

<div
  class="flex flex-wrap justify-between gap-x-6
gap-y-4 content-center mx-page mt-4 md:max-w-xl md:mx-auto"
>
  {#each linkList as linkItem}
    <!-- <div> -->
    {#if linkItem.type === 'url'}
      <a target="_blank" class="cursor-pointer" href={linkItem.content}>
        <Icons
          width={ICON_W}
          height={ICON_W}
          name={linkItem.linkName}
          className="fill-[#818181]"
          stroke="#818181"
        />
      </a>
    {:else}
      <div
        use:copiable={linkItem.content}
        on:click={() => {
          notificationAdapter.toast(`已复制「${linkItem.content}」`)
        }}
      >
        <Icons
          width={ICON_W}
          height={ICON_W}
          name={linkItem.linkName}
          className="fill-[#818181]"
          stroke="#818181"
        />
      </div>
    {/if}
    <!-- </div> -->
  {/each}
  {#each linkList as item}
    <div class="w-[36px] h-0" />
  {/each}
</div>
