<script>
  import Endpoints from '../lib/Endpoints'
  import { creatorInfo_ } from '../services/store'
  import AskQuestionSheet from './AskQuestionSheet.svelte'
  import FoodOfferingSheet from './FoodOfferingSheet.svelte'

  let offeringFood = false
  let askingQuestion = false

  $: avatarUrl = DEV_ENV
    ? `${Endpoints.CORS_PREFIX}/${$creatorInfo_?.avatar}`
    : $creatorInfo_?.avatar
</script>

<div
  class="flex z-50 items-center justify-center py-4 bg-primary text-white translate-y-full  font-bold text-2xl  sticky bottom-[74px]  "
>
  <button
    class="outline-none active:border-white px-6 py-1 border-2 border-white rounded-lg"
    on:click={() => {
      offeringFood = true
    }}
  >
    请吃饭
  </button>
  <button
    class="outline-none  border-none px-4 py-1 ml-6"
    on:click={() => {
      askingQuestion = true
    }}
  >
    免费提问
  </button>
</div>

<!-- the hidden sticky one -->
<div
  class="flex z-40 sticky top-0 bottom-0 items-center py-2 justify-center bg-black/70 backdrop-blur-lg text-white font-bold text-normal  mb-20 "
>
  <button
    class="outline-none active:border-white px-6 py-1 border-2 border-white rounded-lg"
    on:click={() => {
      offeringFood = true
    }}
  >
    请吃饭
  </button>
  <button
    class="outline-none  border-none px-2 py-0.5 ml-6 max-w-[30%]"
    on:click={() => {
      askingQuestion = true
    }}
  >
    免费提问
  </button>
  <div class="flex items-center ml-6 ">
    <img class="w-8 h-8 rounded-xl " src={avatarUrl} alt="avatar of creator" />
    <!-- <h2 class="ml-2  text-ellipsis  overflow-hidden whitespace-nowrap">
      {$creatorInfo_?.name}
    </h2> -->
  </div>
</div>

{#if offeringFood}
  yeah
  <FoodOfferingSheet dismiss={() => (offeringFood = false)}
    >hi there</FoodOfferingSheet
  >
{/if}

{#if askingQuestion}
  <AskQuestionSheet dismiss={() => (askingQuestion = false)} />
{/if}
