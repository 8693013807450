<script lang="ts">
  import Roller from './Roller.svelte'
  import { copiable } from '../lib/actions/Copiable'
  import notificationAdapter from '../services/notificationAdapter'
  import { FoodList } from '../services/foodData'

  export let rollerStyle: string
  export let brandStr: string

  $: foodStr = Array(brandStr.length)
    .fill(0)
    .map((_, idx) => FoodList[idx % FoodList.length])
    .join('')
</script>

<div class="h-screen flex flex-col  ">
  <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="right"
      >{brandStr}</Roller
    >
  </a>

  <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="left"
    >{foodStr}</Roller
  >

  <!-- <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="right"
      >{brandStr}</Roller
    >
  </a>
  <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="left"
      >{brandStr}</Roller
    >
  </a> -->
  <p class="my-10 text-center md-limit text-gray-400 mx-8">
    正在加载中.....如果这几个字一直是这样，那就说明网站出了点问题，请通过
    <span
      use:copiable={'hi@niucodata.com'}
      on:click={() => {
        notificationAdapter.toast(`已复制「hi@niucodata.com」`)
      }}
      class="text-blue-500 hover:text-blue-600 cursor-pointer"
    >
      hi@niucodata.com
    </span>
    跟我们联系
  </p>

  <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="right"
    >{foodStr}</Roller
  >
  <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 my-auto" style={rollerStyle} dir="left"
      >{brandStr}</Roller
    >
  </a>
</div>
