<script lang="ts" context="module">
  import { fade } from 'svelte/transition'
  import { msgList } from '../services/notificationAdapter'
</script>

<div
  class="toast-container fixed w-full h-full top-0 left-0 pointer-events-none px-4"
>
  {#each $msgList as msg}
    <div
      transition:fade|local
      class="toast rounded-full py-2 px-4 mx-auto my-1.5 w-max dark:border break-all break-words"
    >
      {msg}
    </div>
  {/each}
</div>

<style>
  .toast-container {
    z-index: 199999;
    padding-top: 20vh;
  }
  .toast {
    background: #333;
    color: white;
  }
</style>
