import { createPaymentOrder, makePaymentUrl, } from '../domain/order';
import device from '../lib/device';
import mbdUtils from '../lib/mbdUtils';
import API from './API';
const paymentAdapter = {
    async createOrder(orderInfo) {
        const res = await API.createOrder(orderInfo);
        const order = createPaymentOrder({
            orderID: res.result.order_id,
            amount: orderInfo.amount,
            ethAmount: res.ethAmount,
            ethAddress: orderInfo.toEthAddress,
        });
        return order;
    },
    checkMetaMask() {
        // console.log('check', web3, ethereum)
        return window.web3 && window.ethereum;
    },
    async invokePaymentMethod(order) {
        const paymentUrl = makePaymentUrl(order);
        if (device.checkMobile()) {
            // window.location.href = paymentUrl
            window.open(paymentUrl);
            return null;
        }
        else {
            // show qrcode for payment for desktop
            const qrUrl = mbdUtils.genQRCodeURL(paymentUrl);
            return qrUrl;
        }
    },
    async tryPayETH(order) {
        const addressList = await ethereum.request({
            method: 'eth_requestAccounts',
        });
        const txHash = await ethereum.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    from: ethereum.selectedAddress,
                    to: order.toEthAddress,
                    value: order.ethAmount,
                },
            ],
        });
        console.log('txhash', txHash);
        return txHash;
    },
};
export default paymentAdapter;
