import ddfDataAdapter from '../services/ddfDataAdapter';
import { creatorInfo_, isCreatorLogin_, qaPairs_, receivedFoods_, } from '../services/store';
export async function loadAllDdfData(creatorUserKey) {
    const data = await ddfDataAdapter.getDataForCreator(creatorUserKey);
    const { isLogin, creatorInfo, qaList, foodWithMsg, subInfo } = data;
    isCreatorLogin_.set(isLogin);
    qaPairs_.set(qaList);
    receivedFoods_.set(foodWithMsg);
    creatorInfo_.set(creatorInfo);
    return data;
}
