import { writable } from 'svelte/store';
export const msgList = writable([]);
/**
 * duration in seconds
 */
const notificationAdapter = {
    toast(msg, duration = 2) {
        msgList.update(msgs => [...msgs, msg]);
        setTimeout(() => {
            msgList.update(msgs => {
                const idx = msgs.indexOf(msg);
                msgs.splice(idx, 1);
                return msgs;
            });
        }, 1000 * duration);
    },
};
export default notificationAdapter;
