export function makeOrderInfo({ food, sendTo, message, type, toEthAddress, }) {
    return {
        amount: food.price / 100,
        channel: 'tip',
        content: message,
        food: food.name,
        food_count: 1,
        key: sendTo,
        type,
        toEthAddress,
    };
}
export function createPaymentOrder({ orderID, amount, ethAmount, ethAddress, }) {
    return {
        id: orderID,
        type: 'donate',
        amount,
        ethAmount,
        toEthAddress: ethAddress,
    };
}
export function makePaymentUrl(order) {
    return ('https://m.niucodata.com/box_pay/pay_d.php?type=donate&order_id=' +
        order.id +
        '&amount=' +
        order.amount);
}
