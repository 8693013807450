import { get } from 'svelte/store';
import ddfDataAdapter from '../services/ddfDataAdapter';
import notificationAdapter from '../services/notificationAdapter';
import { qaPairs_ } from '../services/store';
export async function answerQuestion({ answer, questionID, }) {
    await ddfDataAdapter.answerQuestion({ answer, questionID });
    const idx = get(qaPairs_).findIndex(q => q.id === questionID);
    await qaPairs_.update(qaPairs => {
        const qaPair = qaPairs[idx];
        qaPair.answer = answer;
        return [...qaPairs.slice(0, idx), qaPair, ...qaPairs.slice(idx + 1)];
    });
    notificationAdapter.toast('已发送回答');
}
export async function setQuestionVisible(questionID, visible) {
    await ddfDataAdapter.setQuestionVisible(questionID, visible);
    if (!visible) {
        const idx = get(qaPairs_).findIndex(q => q.id === questionID);
        if (idx !== -1) {
            qaPairs_.update(qaPairs => {
                return [...qaPairs.slice(0, idx), ...qaPairs.slice(idx + 1)];
            });
        }
        notificationAdapter.toast('已忽略该提问');
    }
}
