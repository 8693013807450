import Endpoints from '../lib/Endpoints';
export default {
    async createOrder(order) {
        const res = await (await fetch(Endpoints.CreateReward, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(order),
        })).json();
        return res;
    },
    askQuestion(questionInput) {
        return fetch(Endpoints.AskQuestion, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(questionInput),
        });
    },
    getCreatorInfo(creatorUserKey) {
        return fetch(Endpoints.CreatorInfo, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                key: creatorUserKey,
            }),
        }).then(res => res.json());
    },
    logEthPayment({ hash, orderID }) {
        return fetch(Endpoints.LOG_HASH, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                hash,
                order_id: orderID,
            }),
        });
    },
    // TODO 补全这个函数，但看起来并不需要请求这些API？
    prepay(order) {
        // TODO 根据支付方式/闪电结算与否，调用不同的接口
        fetch('https://m.niucodata.com/box_pay/pings/example/charge_mbd.php', {
            method: 'POST',
            body: JSON.stringify({
                channel: 'alipay_pc_direct',
                amount: order.amount,
                subject: '赞赏创作者',
                dundun: 'fan',
                orderid: order.id,
            }),
        });
    },
    updateQuestion({ questionID, answer, hide, }) {
        return fetch(Endpoints.AnswerQuestion, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                hide: hide ? 1 : 0,
                q_id: questionID,
                answer: answer !== null && answer !== void 0 ? answer : '',
            }),
        }).then(res => res.ok);
    },
};
