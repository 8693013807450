<script lang="ts">
  import { offerFoodToCreator } from '../application/offerFood'
  import { enabledETHPayment, getEthAddress } from '../domain/creator'

  import device from '../lib/device'
  import Icons from '../lib/icons.svelte'
  import { getFoodById, getFoodListForDisplay } from '../services/foodData'
  import notificationAdapter from '../services/notificationAdapter'
  import paymentAdapter from '../services/paymentAdapter'
  import { creatorInfo_ } from '../services/store'
  import BottomSheet from './BottomSheet.svelte'

  export let dismiss: () => void

  const canPayWithETH =
    enabledETHPayment($creatorInfo_) &&
    getEthAddress($creatorInfo_) &&
    paymentAdapter.checkMetaMask()

  let showMetaMaskNotice = false
  let maybeQRCodeUrl: string | null

  async function handleSubmit(e: SubmitEvent) {
    const data = new FormData(e.target as HTMLFormElement)

    const paymentType = e.submitter.name === 'eth' ? 'eth' : 'normal'

    const foodId: number = data.get('food-id')
    const userName = data.get('user-name') as string
    const message = data.get('user-msg') as string

    if (paymentType === 'eth') {
      showMetaMaskNotice = true
      maybeQRCodeUrl = null
    } else {
      showMetaMaskNotice = false
    }

    const result = await offerFoodToCreator({
      food: getFoodById(foodId),
      creatorUserKey: $creatorInfo_.user_key,
      userName,
      message,
      type: paymentType,
      toEthAddress: getEthAddress($creatorInfo_),
    })

    if (typeof result === 'string') {
      maybeQRCodeUrl = result
    }

    // eth payment result
    if (typeof result === 'boolean') {
      if (result) {
        notificationAdapter.toast('支付成功')
      } else {
        showMetaMaskNotice = false
        notificationAdapter.toast('你取消了支付')
      }
    }

    if (device.checkMobile()) {
      dismiss()
    }

    // TODO  add to received food list
  }

  $: showQRCode = maybeQRCodeUrl?.length > 0
</script>

<BottomSheet {dismiss}>
  <form on:submit|preventDefault={handleSubmit}>
    <h2 class="text-xl mx-4 mt-10 mb-12 text-center">
      请 {$creatorInfo_.name} 吃
      <select
        class="py-1 px-0.5 border border-gray-400 rounded-md bg-transparent"
        name="food-id"
      >
        {#each getFoodListForDisplay() as food}
          <option value={food.id}>
            <div class="">
              {food.name}
            </div>
            <span class="text-sm">
              {food.price / 100}元
            </span>
          </option>
        {/each}
      </select>
    </h2>
    <div class="mx-20 flex flex-col items-center text-center mb-8">
      <!-- <fieldset class=""> -->
      <label for="user-name" class="text-sm text-gray-400">
        你的名字 <sup>选填</sup>
      </label>
      <input
        type="text"
        id="user-name"
        name="user-name"
        class="rounded-sm h-8  p-1  border-transparent border-b-gray-300 text-center border-b max-w-xs  w-full"
        autocomplete="off"
      />
      <!-- </fieldset> -->
      <!-- <fieldset class="mx-4"> -->
      <label for="user-msg" class="text-sm text-gray-400 mt-4">
        你想说的话 <sup>选填</sup>
      </label>
      <input
        class="text-center resize-none p-1 mb-4 h-8 rounded-sm border-transparent border-b border-b-gray-300  max-w-xs  w-full"
        type="text "
        id="user-msg"
        name="user-msg"
      />
      <!-- </fieldset> -->
    </div>
    <div class="flex mt-auto">
      {#if canPayWithETH}
        <button
          name="eth"
          class="flex-1 font-bold py-5 text-2xl bg-secondary text-gray-500 border-none rounded-none"
          ><Icons name="ethereum" className="inline-block fill-gray-500" /> ETH付款
        </button>
      {/if}

      <button
        type="submit"
        name="normal"
        class="flex-1 text-center font-bold py-5 text-2xl bg-primary text-white border-none rounded-none  "
        >付款</button
      >
    </div>
    {#if showMetaMaskNotice}
      <div class=" text-center my-6 text-gray-400">
        <h2 class="font-bold text-xl leading-6">正在使用 MetaMask 付款</h2>
        <p class="mx-4">
          扫描完成后请刷新此页面
          <br />
          在8个确认后，我们将标记为成功，并展示食物和留言
        </p>
      </div>
    {/if}
    {#if showQRCode}
      <figure class="text-center my-6 text-gray-400 ">
        <img class="w-32 inline" src={maybeQRCodeUrl} alt="" />
        <figcaption>
          <div>
            请扫码付款后 <span
              class="underline text-blue-500 inline-flex items-center cursor-pointer"
              on:click={() => {
                window.location.reload()
              }}
            >
              刷新页面<Icons name="refresh" />
            </span>
          </div>
          <small class="inline-flex">
            *支持<Icons className="inline" name="we-pay" />微信和<Icons
              className="inline"
              name="alipay"
            />支付宝</small
          >
        </figcaption>
      </figure>
    {/if}
  </form>
</BottomSheet>
