const captchaAdapter = {
    verify() {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                const captcha = new TencentCaptcha(String(TCAPTCHA_APP_ID), (res) => {
                    if (res.ret === 2 || res.errorCode !== undefined) {
                        reject(res);
                    }
                    else {
                        resolve(res);
                    }
                });
                captcha.show(); // 展示验证码
            }
            catch (e) {
                reject(e);
            }
        });
    },
};
export default captchaAdapter;
