<script lang="ts">
  export let dir: 'left' | 'right' = 'left'
  export let style = ''
  export let className = ''
  export let flex = true
</script>

<div
  class="roller h-min whitespace-nowrap  overflow-visible {dir === 'left'
    ? 'move-left'
    : 'move-right'} {className}"
  style="{flex
    ? 'width:200%;display:flex;'
    : 'overflow:visible;width:fit-content;'} {style} "
>
  <div
    class="roll {flex
      ? 'w-1/2'
      : ''} inline-block leading-none overflow-visible"
  >
    <slot />
  </div>
  <div
    class="roll {flex
      ? 'w-1/2'
      : ''} inline-block  leading-none overflow-visible"
  >
    <slot />
  </div>
</div>

<style>
  .roller {
    --roll-duration: 29s;
    overflow: hidden;
    word-wrap: none;
  }

  .move-left {
    animation: move-left var(--roll-duration) linear infinite;
  }
  .move-right {
    animation: move-right var(--roll-duration) linear infinite;
  }
  /*
  @media (min-width: 400px) {
    .roller {
      --roll-duration: 26s;
    }
  }

  */

  @media (min-width: 900px) {
    .roller {
      --roll-duration: 40s;
    }
  }

  @media (min-width: 1440px) {
    .roller {
      --roll-duration: 50s;
    }
  }

  @keyframes move-left {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes move-right {
    0% {
      transform: translateX(-50%);
    }

    100% {
      transform: translateX(0);
    }
  }
</style>
