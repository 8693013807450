// 请创作者吃饭
import { makeOrderInfo } from '../domain/order';
import { createMessageToCreator } from '../domain/userMessage';
import API from '../services/API';
import '../services/notificationAdapter';
import paymentAdapter from '../services/paymentAdapter';
export async function offerFoodToCreator({ food, creatorUserKey, message, userName, type, toEthAddress, }) {
    const orderInfo = makeOrderInfo({
        food,
        type,
        sendTo: creatorUserKey,
        message: createMessageToCreator({ userName, msg: message }),
        toEthAddress,
    });
    const paymentOrder = await paymentAdapter.createOrder(orderInfo);
    // .then(paymentOrder => {
    if (type === 'normal') {
        return paymentAdapter.invokePaymentMethod(paymentOrder);
    }
    else if (type === 'eth' &&
        paymentOrder.ethAmount &&
        paymentOrder.toEthAddress) {
        return paymentAdapter
            .tryPayETH(paymentOrder)
            .then(txHash => {
            API.logEthPayment({ hash: txHash, orderID: paymentOrder.id });
            return true;
        })
            .catch(e => {
            console.log('payment error', e);
            return false;
        });
    }
    // })
}
