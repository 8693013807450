function copy(text) {
    const fake = document.body.appendChild(document.createElement('textArea'));
    fake.style.position = 'absolute';
    fake.style.left = '-9999px';
    fake.setAttribute('readonly', '');
    fake.value = '' + text;
    fake.select();
    try {
        document.execCommand('copy');
        return true;
    }
    catch (err) {
        return false;
    }
    finally {
        fake.parentNode.removeChild(fake);
    }
}
export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        return copy(text);
    }
    return navigator.clipboard.writeText(text).then(() => true, () => false);
}
export function copiable(node, content = '') {
    const handleClick = evt => {
        if ((node === null || node === void 0 ? void 0 : node.contains(evt.target)) && !evt.defaultPrevented) {
            copyTextToClipboard(content.length > 0 ? content : node.textContent);
        }
    };
    document.addEventListener('click', handleClick, true);
    return {
        destroy() {
            document.removeEventListener('click', handleClick, true);
        },
    };
}
