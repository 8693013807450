<script lang="ts">
  import { onMount } from 'svelte'

  import { getAllValidMessage, sortFoodByType } from '../domain/food'
  import Icons from '../lib/icons.svelte'
  import { receivedFoods_ } from '../services/store'

  $: foodByType = sortFoodByType($receivedFoods_)
  $: messages = getAllValidMessage($receivedFoods_)

  let idx = 0
  let timer: NodeJS.Timer
  let elContainer: HTMLDivElement | null = null

  onMount(() => {
    const duration =
      window.innerWidth > 1440 ? 28 : window.innerWidth > 768 ? 22 : 17
    const loadInterval = 2100
    elContainer.style.setProperty('--window-width', `-${window.innerWidth}px`)

    timer = setInterval(() => {
      const el = document.createElement('div')
      const elName = document.createElement('span')
      elName.className = 'bullet-uname'
      const elMsg = document.createElement('span')
      const [uname, msg] = messages[idx]?.message?.split(':')

      elName.innerText = uname
      const hasMsg = msg?.trim().length > 0
      const hasName = uname?.trim().length > 0
      elMsg.innerText = `${hasName ? ' · ' : ''}${messages[idx].name}${
        hasMsg ? ' · ' + msg : ''
      }`

      el.append(elName, elMsg)
      el.style.position = 'absolute'
      el.style.left = '100%'
      el.style.top = (idx % 3) * 28 + 'px'
      el.style.overflow = 'visible'
      el.style.whiteSpace = 'nowrap'
      el.style.transform = 'translateX(150%)'
      el.className = 'bullet'

      elContainer.append(el)
      setTimeout(() => {
        elContainer.removeChild(el)
      }, duration * 1000 + 4000)

      idx++
      if (idx >= messages.length) {
        idx = 0
      }
    }, loadInterval)

    return () => {
      clearInterval(timer)
    }
  })
</script>

<div
  class="md-limit mt-4 whitespace-pre-wrap flex flex-wrap border-[0.5px] border-gray-200 w-fit select-none mx-auto"
>
  {#each Object.values(foodByType) as foodType}
    <div
      class=" py-2 px-4 border-[0.5px] border-gray-200  text-gray-500 font-bold text-2xl flex-1 w-max whitespace-nowrap flex-nowrap  flex items-center justify-center active:bg-gray-200 active:scale-110 origin-center transition-transform"
    >
      <div class="w-24 min-w-[64px] flex items-center ">
        <div>{foodType.data[0].name}</div>
        <Icons name="x" className="mx-1 min-w-[20px]" />
        <div class="">{foodType.amount}</div>
      </div>
    </div>
  {/each}
</div>

<div class="mt-4 mb-8 pb-1" bind:this={elContainer} />

<style global>
  .bullet {
    background-color: white;
    padding: 2 6px;
    border-radius: 4px;
    --bullet-duration: 28s;
    /* --window-width: -768px; */
    animation: fly var(--bullet-duration) linear;
  }

  @media (max-width: 1440px) {
    .bullet {
      --bullet-duration: 22s;
    }
  }

  @media (max-width: 768px) {
    .bullet {
      --bullet-duration: 17s;
    }
  }

  .bullet-uname {
    @apply text-gray-400;
  }

  @keyframes fly {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(calc(-120% + var(--window-width)));
    }
  }
</style>
