import { copyTextToClipboard } from '../lib/actions/Copiable';
import device from '../lib/device';
import notificationAdapter from './notificationAdapter';
const shareAdapter = {
    async share(shareData) {
        // console.log('canShare' in navigator, navigator.share)
        if ('canShare' in navigator && navigator.canShare(shareData)) {
            navigator.share(shareData);
        }
        else if (device.checkWechat()) {
            // TODO wechat share
            alert('麻烦点击右上角⤴ 即可分享到朋友圈或微信群');
        }
        else {
            await copyTextToClipboard(shareData.url);
            notificationAdapter.toast('已复制链接到剪贴板');
        }
    },
};
export default shareAdapter;
