<script lang="ts">
  import { askQuestion } from '../application/askQuestion'

  import { creatorInfo_ } from '../services/store'

  import BottomSheet from './BottomSheet.svelte'
  export let dismiss: () => void

  let elForm: HTMLFormElement | null = null

  async function handleSubmit() {
    const data = new FormData(elForm)
    const res = await askQuestion({
      question: data.get('question') as string,
      creatorUserKey: $creatorInfo_.user_key,
    })
    dismiss()
  }
</script>

<BottomSheet {dismiss}>
  <form
    bind:this={elForm}
    class="flex flex-col  "
    on:submit|preventDefault={handleSubmit}
  >
    <h2 class="mb-4 text-xl mx-4">
      向 {$creatorInfo_.name} 免费发起匿名提问
    </h2>
    <textarea
      autofocus
      required
      class=" mx-4 border-gray-600 border resize-none p-3"
      name="question"
      rows="0"
    />
    <i class="mb-6 mx-4 text-gray-400 text-sm">礼貌提问，大家都开心。</i>
    <button
      class="w-full text-center font-bold py-5 text-2xl bg-primary text-white border-none rounded-none mt-auto "
      type="submit">发送提问</button
    >
  </form>
</BottomSheet>
