export function getAllValidMessage(foodsWithMessage) {
    return foodsWithMessage.filter(food => food.message &&
        food.message.trim().length > 0 &&
        (food.message.trim() !== ':' || food.message.trim() !== '：'));
}
export function getAllFoodString(foodsWithMessage) {
    return foodsWithMessage
        .map(food => Array(food.amount).fill(food.name).join(''))
        .join('');
}
export function sortFoodByType(foodsWithMessage) {
    const foodsByType = foodsWithMessage.reduce((acc, food) => {
        var _a;
        acc[food.id] = acc[food.id] || { amount: 0, data: [] };
        acc[food.id].data = (((_a = acc[food.id]) === null || _a === void 0 ? void 0 : _a.data) || []).concat(food);
        return acc;
    }, {});
    Object.keys(foodsByType).forEach(foodID => {
        foodsByType[foodID].amount = foodsByType[foodID].data.reduce((acc, food) => acc + food.amount, 0);
    });
    return foodsByType;
}
export function getFoodStringLength(foodsWithMessage) {
    return foodsWithMessage.reduce((sum, food) => sum + food.amount, 0);
}
