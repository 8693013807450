<script lang="ts">
  import DOM from '../lib/DOM'
  import Icons from '../lib/icons.svelte'

  export let className = ''
  export let dismiss: () => void

  let elPanel: HTMLDivElement | null = null
</script>

<div
  class="fixed z-[9999] left-0 top-0 w-screen min-h-full flex flex-col backdrop-blur-md  bg-black/50  "
  on:click={e => {
    if (!DOM.isChildOf(e.target, elPanel)) {
      dismiss()
    }
  }}
>
  <div class=" flex-grow md:flex-grow-0 " />
  <div
    class=" bg-white pt-8 max-w-lg w-full mx-auto min-h-[160px] md:my-auto md:rounded-lg overflow-hidden {className}"
    bind:this={elPanel}
  >
    <button on:click={dismiss} class="border-none absolute right-2 top-2 z-50 ">
      <Icons className="" name="x" />
    </button>
    <slot><!-- optional fallback --></slot>
  </div>
</div>
