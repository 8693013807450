import '../lib/Endpoints';
import API from './API';
import captchaAdapter from './captchaAdapter';
import { getFoodByName } from './foodData';
const ddfDataAdapter = {
    async answerQuestion({ questionID, answer }) {
        return API.updateQuestion({ questionID, answer });
    },
    askQuestion({ question, creatorUserKey }) {
        return captchaAdapter.verify().then(captcha => {
            const questionInput = {
                question,
                to_user: creatorUserKey,
                ticket: captcha.ticket,
                randstr: captcha.randstr,
            };
            return API.askQuestion(questionInput);
        });
    },
    setQuestionVisible(questionID, visible) {
        return API.updateQuestion({ questionID, hide: !visible });
    },
    async getDataForCreator(creatorUserKey) {
        var _a, _b, _c;
        const res = await API.getCreatorInfo(creatorUserKey);
        const isLogin = res.creator === 'ontology';
        return {
            isLogin,
            creatorInfo: res.user_info,
            qaList: (_a = res.qa) !== null && _a !== void 0 ? _a : [],
            foodWithMsg: (_c = (_b = res.rewards) === null || _b === void 0 ? void 0 : _b.map(food => {
                const foodObj = getFoodByName(food.food);
                return Object.assign(Object.assign({}, foodObj), { message: food.content, amount: parseInt(food.food_count) });
            })) !== null && _c !== void 0 ? _c : [],
            subInfo: res.sub_info,
        };
    },
};
export default ddfDataAdapter;
