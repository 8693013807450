<script lang="ts">
  import { onMount } from 'svelte'
  import { loadAllDdfData } from './application/loadAllDdfData'
  import { updateThemeColor } from './application/updateThemColor'
  import CtaSection from './components/CTASection.svelte'
  import FoodStats from './components/FoodStats.svelte'
  import QASection from './components/QASection.svelte'
  import Roller from './components/Roller.svelte'
  import SocialLinks from './components/SocialLinks.svelte'
  import { filterVisibleQAItems } from './domain/question'
  import device from './lib/device'
  import Endpoints from './lib/Endpoints'
  import Icons from './lib/icons.svelte'
  import shareAdapter from './services/shareAdapter'
  import { creatorInfo_, isCreatorLogin_, qaPairs_ } from './services/store'
  import InitLoading from './components/InitLoading.svelte'
  import BottomSheet from './components/BottomSheet.svelte'

  const repeatTimes =
    window.innerWidth < 400
      ? 4
      : window.innerWidth < 800
      ? 6
      : window.innerWidth < 1200
      ? 9
      : 11
  const BRAND_STR = Array(repeatTimes).fill('顿顿饭').join('')
  const rollerStyle = `font-size:${
    document.body.clientWidth / BRAND_STR.length
  }px; ${device.checkIOS() ? 'gap:4px;' : ''} width:fit-content;`

  // let elAvatar: HTMLImageElement | null = null

  $: title = `${$creatorInfo_?.name}的个人主页 - 顿顿饭`
  $: avatarUrl = DEV_ENV
    ? `${Endpoints.CORS_PREFIX}/${$creatorInfo_?.avatar}`
    : $creatorInfo_?.avatar

  onMount(async () => {
    const creatorUserKey = location.pathname.substring(2)
    loadAllDdfData(creatorUserKey)
  })
</script>

<svelte:head>
  {#if $creatorInfo_?.name.length > 0}
    <title>{title}</title>
    <meta property="og:title" content={title} />
  {/if}
</svelte:head>

{#if $creatorInfo_}
  <!-- <BottomSheet dismiss={() => {}}>
    <img
      class="w-96 h-96"
      src="{Endpoints.SHARE_CARD}/{$creatorInfo_?.user_key}"
      alt=""
    />
  </BottomSheet> -->
  <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 " style={rollerStyle} dir="right"
      >{BRAND_STR}</Roller
    >
  </a>
{/if}

{#if !$creatorInfo_}
  <InitLoading {rollerStyle} brandStr={BRAND_STR} />
{:else}
  <main class="min-h-screen ">
    {#if $isCreatorLogin_}
      <div class="font-bold mt-4 md-limit mx-auto w-full flex justify-center ">
        <a
          class="inline-flex py-2 px-4 rounded-xl border border-gray-600 no-underline !text-gray-600"
          href="https://dun.mianbaoduo.com/edit"
          >编辑此页面<Icons name="edit" /></a
        >
        <button
          class="ml-2 inline-flex py-2 px-4 bg-secondary border-none rounded-xl font-bold"
          on:click={() => {
            shareAdapter.share({
              title: '顿顿饭',
              text: `来顿顿饭请${$creatorInfo_.name}吃饭😋或提问📦`,
              url: location.href,
            })
          }}>分享此页面 <Icons name="share" /></button
        >
      </div>
      else content here
    {/if}

    <!-- avatar -->
    <div
      class="flex items-center md:mb-2 mt-12 mx-page md-limit md:justify-center md:flex-col"
    >
      <img
        on:load={e => {
          updateThemeColor(e.target)
        }}
        crossorigin="anonymous"
        class="w-10 h-10 rounded-xl  md:w-20 md:h-20 md:mt-4 md:mb-2 md:rounded-3xl"
        src={avatarUrl}
        alt="avatar of creator"
      />
      <h1
        class="ml-2 md:ml-0 text-2xl text-ellipsis max-w-full overflow-hidden whitespace-nowrap"
      >
        {$creatorInfo_?.name}
      </h1>
    </div>

    <!-- bio -->
    <h2 class="heading mt-8 font-serif h-[50px] md-limit md:translate-x-[-5%] ">
      <span class="text-9xl absolute -left-1 "> “ </span>
    </h2>
    <p class="text-neutral-500 pb-2 mx-page whitespace-pre-line md-limit">
      {$creatorInfo_?.brief?.length > 0
        ? $creatorInfo_.brief
        : `你好 👋，我是${$creatorInfo_.name}，你可以在这里请我吃饭或者提问。`}
    </p>

    <!-- {#if !$creatorInfo_?.brief}
    <hr class="mx-page md-limit mb-4" />
  {/if} -->

    <SocialLinks socialLinkObject={$creatorInfo_?.icons} />

    <h2 class="heading mt-8 md-limit md:translate-x-[-5%]">收到的食物&留言</h2>
    <FoodStats />

    <CtaSection />

    <h2 class="heading mt-12 mb-2 top-0 md-limit md:translate-x-[-5%]">
      收到的提问
    </h2>
    <QASection qaList={filterVisibleQAItems($qaPairs_, $isCreatorLogin_)} />
  </main>
{/if}

{#if $creatorInfo_}
  <a href="https://dun.mianbaoduo.com">
    <Roller className="text-neutral-300 mt-12" style={rollerStyle}
      >{BRAND_STR}</Roller
    >
  </a>
{/if}
