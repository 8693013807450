import * as Vibrant from 'node-vibrant/dist/vibrant.worker';
export async function updateThemeColor(elImg) {
    let v = await Vibrant.from(
    // elImg
    elImg.src
    // 'http://jariz.github.io/vibrant.js/examples/4.jpg'
    )
        // .maxColorCount(256)
        .quality(1)
        // .useQuantizer(Vibrant.Quantizer.WebWorker)
        .getPalette();
    const hue = v.Vibrant.hsl[0] * 360;
    const dark = v.DarkVibrant;
    // console.log('color', hue, v)
    // const primaryClr = v.DarkVibrant.hex
    const primaryClr = `hsl(${hue}, 58%, 55%)`;
    // const secondaryClr = `rgba(${dark.r},${dark.g},${dark.b}, 0.5)`
    const secondaryClr = `hsla(${hue}, 40%, 60%,0.35)`;
    document.body.style.setProperty('--primary-color', primaryClr);
    document.body.style.setProperty('--secondary-color', secondaryClr);
}
