<script lang="ts">
  import type { UserQuestionWithAnswer } from '../domain/question'
  import {
    answerQuestion,
    setQuestionVisible,
  } from '../application/questionEdit'

  import BottomSheet from './BottomSheet.svelte'

  export let dismiss: () => void
  export let qaPair: UserQuestionWithAnswer | null
</script>

{#if qaPair}
  <BottomSheet {dismiss}>
    <form
      class="flex flex-col"
      on:submit|preventDefault={e => {
        const data = new FormData(e.currentTarget)

        answerQuestion({
          questionID: qaPair.id,
          answer: data.get('answer').trim(),
        })
        dismiss()
      }}
    >
      <h2 class="mb-4 text-xl mx-4">
        {qaPair.question}
      </h2>
      <textarea
        required
        class=" mx-4 border-gray-600 border resize-none p-3 mb-6 h-24"
        name="answer"
        rows="0"
      />
      <div class="flex w-full mt-auto">
        <button
          type="button"
          on:click={() => {
            const sure = confirm('被忽略的提问将不会再出现，确定要忽略吗？')
            if (sure) {
              setQuestionVisible(qaPair.id, false)
              dismiss()
            }
          }}
          class="flex-1 text-center font-bold py-5 text-2xl bg-primary text-gray-700 border-none rounded-none bg-secondary"
        >
          忽略/不回答
        </button>
        <button
          class="flex-1 text-center font-bold py-5 text-2xl bg-primary text-white border-none rounded-none  "
          >发送回答</button
        >
      </div>
    </form>
  </BottomSheet>
{/if}
